import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'

// axios
import axios from "./plugins/axios.js"
Vue.prototype.$http = axios

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// Install BootstrapVue & Optionally BootstrapVue icon components plugin
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import '@/plugins/vee-validate.js'
import '@/plugins/toastification'
import '@/plugins/sweet-alerts'

// Filters
import './filters/filters'

// Mixins
import mixins from './mixins/mixins'
Vue.mixin(mixins);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
