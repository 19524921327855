export default [
  {
  // =============================================================================
  // MAIN LAYOUT ROUTES
  // =============================================================================
      path: '',
      redirect: '/',
      component: () => import('@/layouts/Main.vue'),
      children: [
          {
              path: '/',
              name: 'dashboard',
              component: () => import('@views/Dashboard.vue'),
              meta: {
                breadcrumb: [
                  {
                    text: "Dashboard",
                    active: true
                  }
                ],
                pageTitle: 'Welcome',
                authRequired: true,
              }
          },
          {
              path: '/account',
              name: 'account',
              component: () => import('@views/account/UserEdit.vue'),
              meta: {
                 breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{path:"/"}
                    },
                    {
                      text: "Account",
                      active: true
                    }
                ],
                pageTitle: 'Profile',
                authRequired: true,
              },
          },
          {
              path: '/orders',
              name: 'order',
              component: () => import('@views/orders/OrderList.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{path:"/"}
                    },
                    {
                      text: "Orders",
                      active: true
                    }
                ],
                pageTitle: "Orders List",
                authRequired: true,
              },
          },
          {
              path: '/orders/:orderId(\\d+)',
              name: 'order-view',
              component: () => import('@views/orders/OrderShow.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Orders",
                      to:{ path: "/orders"}
                    },
                    {
                      text: "Show",
                      active: true
                    }
                ],
                pageTitle: "View Order",
                authRequired: true,
              },
          },
          {
              path: '/notifications',
              name: 'notifications',
              component: () => import('@views/notifications/NotificationList.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{path:"/"}
                    },
                    {
                      text: "Notifications List",
                      active: true
                    }
                ],
                pageTitle: "Notifications",
                authRequired: true,
              }
          },
      ]
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
      path: '',
      component: () => import('@/layouts/FullPage.vue'),
      children: [
          {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: { pageTitle: "Page not found" }
          },
          {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/pages/Error500.vue'),
            meta: { pageTitle: "500 Error" }
          },
          {
            path: '/auth',
            name: 'auth',
            component: () => import('@/views/pages/Authenticate.vue'),
            meta: { pageTitle: "Auto auth" }
          },
      ]
  },
  // Redirect to 404 page, if no match found
  {
      path: '*',
      redirect: '/error-404'
  },

]