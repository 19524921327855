
<template>
	<div id="appp" :class="vueAppClasses">
    <div v-if="layout.loader" class="busy-container position-relative">
      <div class="app-busy-mood text-center d-flex justify-content-center align-items-center">
          <img src="/images/loading.gif" width="60">
      </div>
    </div>
		<router-view />
	</div>
</template>

<script>

export default {
  data() {
    return {
      vueAppClasses: [],
    }
  },
  computed: {
    layout()  { return this.$store.state.layout },
  },
}

</script>

<style lang="scss">
  // Import Main styles for this application
  @import '@assets/scss/app';
</style>
