export default
[
    { id: 1, name: 'Afghanistan',	code:  'AF', icon: 'af.png'},
    { id: 2, name: 'Albania',	code:  'AL', icon: 'al.png'},
    { id: 3, name: 'Algeria',	code:  'DZ', icon: 'dz.png'},
    { id: 4, name: 'American Samoa', code:  'AS', icon: 'as.png'},
    { id: 5, name: 'Andorra',	code:  'AD', icon: 'ad.png'},
    { id: 6, name: 'Angola',	code:  'AO', icon: 'ao.png'},
    { id: 7, name: 'Anguilla',	code:  'AI', icon: 'ai.png'},
    { id: 8, name: 'Antarctica',	code:  'AQ', icon: 'aq.png'},
    { id: 9, name: 'Antigua and Barbuda', code:  'AG', icon: 'ag.png'},
    { id: 10, name: 'Argentina',	code:  'AR', icon: 'ar.png'},
    { id: 11, name: 'Armenia',	code:  'AM', icon: 'am.png'},
    { id: 12, name: 'Aruba',	code:  'AW', icon: 'aw.png'},
    { id: 13, name: 'Australia',	code:  'AU', icon: 'au.png'},
    { id: 14, name: 'Austria',	code:  'AT', icon: 'at.png'},
    { id: 15, name: 'Azerbaijan',	code:  'AZ', icon: 'az.png'},
    { id: 16, name: 'Bahamas (the)', code:  'BS', icon: 'bs.png'},
    { id: 17, name: 'Bahrain',	code:  'BH', icon: 'bh.png'},
    { id: 18, name: 'Bangladesh',	code:  'BD', icon: 'bd.png'},
    { id: 19, name: 'Barbados',	code:  'BB', icon: 'bb.png'},
    { id: 20, name: 'Belarus',	code:  'BY', icon: 'by.png'},
    { id: 21, name: 'Belgium',	code:  'BE', icon: 'be.png'},
    { id: 22, name: 'Belize',	code:  'BZ', icon: 'bz.png'},
    { id: 23, name: 'Benin',	code:  'BJ', icon: 'bj.png'},
    { id: 24, name: 'Bermuda',	code:  'BM', icon: 'bm.png'},
    { id: 25, name: 'Bhutan',	code:  'BT', icon: 'bt.png'},
    { id: 26, name: 'Bolivia', code:  'BO', icon: 'bo.png'},
    { id: 27, name: 'Bonaire', code:  'BG', icon: 'bq.png'},
    { id: 28, name: 'Bosnia', code:  'BA', icon: 'ba.png'},
    { id: 29, name: 'Botswana',	code:  'BW', icon: 'bw.png'},
    { id: 30, name: 'Bouvet Isla', code:  'BV', icon: 'bv.png'},
    { id: 31, name: 'Brazil',	code:  'BR', icon: 'br.png'},
    { id: 32, name: 'British Indian', code:  'IO', icon: 'io.png'},
    { id: 33, name: 'Brunei', code:  'am', icon: 'am.png'},
    { id: 34, name: 'Bulgaria', code:  'BG', icon: 'bg.png'},
    { id: 35, name: 'Burkina Faso', code:  'BF', icon: 'bf.png'},
    { id: 36, name: 'Burundi', code:  'BI', icon: 'bi.png'},
    { id: 37, name: 'Cabo Verde', code:  'cv', icon: 'cv.png'},
    { id: 38, name: 'Cambodia',	code:  'KH', icon: 'kh.png'},
    { id: 39, name: 'Cameroon',	code:  'CM', icon: 'cm.png'},
    { id: 40, name: 'Canada',	code:  'CA', icon: 'ca.png'},
    { id: 41, name: 'Cayman Islan', code:  'KY', icon: 'ky.png'},
    { id: 42, name: 'Central African Republic', code:  'CF', icon: 'cf.png'},
    { id: 43, name: 'Chad',	code:  'TD', icon: 'td.png'},
    { id: 44, name: 'Chile',	code:  'CL', icon: 'cl.png'},
    { id: 45, name: 'China',	code:  'CN', icon: 'cn.png'},
    { id: 46, name: 'Christmas Island', code:  'CX', icon: 'cx.png'},
    { id: 47, name: 'Cocos', code:  'cc', icon: 'cc.png'},
    { id: 48, name: 'Colombia',	code:  'CO', icon: 'co.png'},
    { id: 49, name: 'Comoros', code:  'KM', icon: 'km.png'},
    { id: 50, name: 'Congo (the Democratic Republic of the)', code:  'CD', icon: 'cd.png'},
    { id: 51, name: 'Congo', code:  'CG', icon: 'cg.png'},
    { id: 52, name: 'Cook Islands', code:  'CK', icon: 'ck.png'},
    { id: 53, name: 'Costa Rica', code:  'CR', icon: 'cr.png'},
    { id: 54, name: 'Croatia',	code:  'HR', icon: 'hr.png'},
    { id: 55, name: 'Cuba',	code:  'CU', icon: 'cu.png'},
    { id: 56, name: 'Curaçao',	code:  'CW', icon: 'cw.png'},
    { id: 57, name: 'Cyprus',	code:  'CY', icon: 'cy.png'},
    { id: 58, name: 'Czechia',	code:  'CZ', icon: 'cz.png'},
    { id: 59, name: 'Côte d Ivoire', code:  'CI', icon: 'ci.png'},
    { id: 60, name: 'Denmark',	code:  'DK', icon: 'dk.png'},
    { id: 61, name: 'Djibouti',	code:  'DJ', icon: 'dj.png'},
    { id: 62, name: 'Dominica',	code:  'DM', icon: 'dm.png'},
    { id: 63, name: 'Dominican Republic', code:  'DO', icon: 'do.png'},
    { id: 64, name: 'Ecuador',	code:  'EC', icon: 'ec.png'},
    { id: 65, name: 'Egypt',	code:  'EG', icon: 'eg.png'},
    { id: 66, name: 'El Salvador', code:  'SV', icon: 'sv.png'},
    { id: 67, name: 'Equatorial Guinea', code:  'GQ', icon: 'gq.png'},
    { id: 68, name: 'Eritrea',	code:  'ER', icon: 'er.png'},
    { id: 69, name: 'Estonia',	code:  'EE', icon: 'ee.png'},
    { id: 70, name: 'Eswatini',	code:  'SZ', icon: 'sz.png'},
    { id: 71, name: 'Ethiopia',	code:  'ET', icon: 'et.png'},
    { id: 72, name: 'Falkland Islands', code:  'FK', icon: 'fk.png'},
    { id: 73, name: 'Faroe Islands', code:  'FO', icon: 'fo.png'},
    { id: 74, name: 'Fiji',	code:  'FJ', icon: 'fj.png'},
    { id: 75, name: 'Finland',	code:  'FI', icon: 'fi.png'},
    { id: 76, name: 'France',	code:  'FR', icon: 'fr.png'},
    { id: 77, name: 'French Guiana', code:  'GF', icon: 'gf.png'},
    { id: 78, name: 'French Polynesia', code:  'PF', icon: 'pf.png'},
    { id: 79, name: 'French Southern', code:  'TF', icon: 'tf.png'},
    { id: 80, name: 'Gabon',	code:  'GA', icon: 'ga.png'},
    { id: 81, name: 'Gambia', code:  'GM', icon: 'gm.png'},
    { id: 82, name: 'Georgia', code:  'GE', icon: 'ge.png'},
    { id: 83, name: 'Germany', code:  'DE', icon: 'de.png'},
    { id: 84, name: 'Ghana',	code:  'GH', icon: 'gh.png'},
    { id: 85, name: 'Gibraltar',	code:  'GI', icon: 'gi.png'},
    { id: 86, name: 'Greece',	code:  'GR', icon: 'gr.png'},
    { id: 87, name: 'Greenland',	code:  'GL', icon: 'gl.png'},
    { id: 88, name: 'Grenada',	code:  'GD', icon: 'gd.png'},
    { id: 89, name: 'Guadeloupe',	code:  'GP', icon: 'gp.png'},
    { id: 90, name: 'Guam',	code:  'GU', icon: 'gu.png'},
    { id: 91, name: 'Guatemala',	code:  'GT', icon: 'gt.png'},
    { id: 92, name: 'Guernsey',	code:  'GG', icon: 'gg.png'},
    { id: 93, name: 'Guinea',	code:  'GN', icon: 'gn.png'},
    { id: 94, name: 'Guinea-Bissau', code:  'GW', icon: 'gw.png'},
    { id: 95, name: 'Guyana',	code:  'GY', icon: 'gy.png'},
    { id: 96, name: 'Haiti',	code:  'HT', icon: 'ht.png'},
    { id: 97, name: 'Heard Island and McDonald Islands', code:  'HM', icon: 'hm.png'},
    { id: 98, name: 'Holy See', code:  'VA', icon: 'va.png'},
    { id: 99, name: 'Honduras',	code:  'HN', icon: 'hn.png'},
    { id: 100, name: 'Hong Kong', code:  'HK', icon: 'hk.png'},
    { id: 101, name: 'Hungary',	code:  'HU', icon: 'hu.png'},
    { id: 102, name: 'Iceland',	code:  'IS', icon: 'is.png'},
    { id: 103, name: 'India',	code:  'IN', icon: 'in.png'},
    { id: 104, name: 'Indonesia',	code:  'ID', icon: 'id.png'},
    { id: 105, name: 'Iran', code:  'IR', icon: 'ir.png'},
    { id: 106, name: 'Iraq',	code:  'IQ', icon: 'iq.png'},
    { id: 107, name: 'Ireland',	code:  'IE', icon: 'ie.png'},
    { id: 108, name: 'Isle of Man', code:  'IM', icon: 'im.png'},
    { id: 109, name: 'Israel',	code:  'IL', icon: 'il.png'},
    { id: 110, name: 'Italy',	code:  'IT', icon: 'it.png'},
    { id: 111, name: 'Jamaica',	code:  'JM', icon: 'jm.png'},
    { id: 112, name: 'Japan',	code:  'JP', icon: 'jp.png'},
    { id: 113, name: 'Jersey',	code:  'JE', icon: 'je.png'},
    { id: 114, name: 'Jordan',	code:  'JO', icon: 'jo.png'},
    { id: 115, name: 'Kazakhstan',	code:  'KZ', icon: 'kz.png'},
    { id: 116, name: 'Kenya',	code:  'KE', icon: 'ke.png'},
    { id: 117, name: 'Kiribati',	code:  'KI', icon: 'ki.png'},
    { id: 118, name: 'Korea KP', code:  'KP', icon: 'kp.png'},
    { id: 119, name: 'Korea KR', code:  'KR', icon: 'kr.png'},
    { id: 120, name: 'Kuwait',	code:  'KW', icon: 'kw.png'},
    { id: 121, name: 'Kyrgyzstan',	code:  'KG', icon: 'kg.png'},
    { id: 122, name: 'Lao', code:  'LA', icon: 'la.png'},
    { id: 123, name: 'Latvia',	code:  'LV', icon: 'lv.png'},
    { id: 124, name: 'Lebanon',	code:  'LB', icon: 'lb.png'},
    { id: 125, name: 'Lesotho',	code:  'LS', icon: 'ls.png'},
    { id: 126, name: 'Liberia',	code:  'LR', icon: 'lr.png'},
    { id: 127, name: 'Libya',	code:  'LY', icon: 'ly.png'},
    { id: 128, name: 'Liechtenstein',	code:  'LI', icon: 'li.png'},
    { id: 129, name: 'Lithuania',	code:  'LT', icon: 'lt.png'},
    { id: 130, name: 'Luxembourg',	code:  'LU', icon: 'lu.png'},
    { id: 131, name: 'Macao',	code:  'MO', icon: 'mo.png'},
    { id: 132, name: 'Madagascar',	code:  'MG', icon: 'mg.png'},
    { id: 133, name: 'Malawi',	code:  'MW', icon: 'mw.png'},
    { id: 134, name: 'Malaysia',	code:  'MY', icon: 'my.png'},
    { id: 135, name: 'Maldives',	code:  'MV', icon: 'mv.png'},
    { id: 136, name: 'Mali',	code:  'ML', icon: 'ml.png'},
    { id: 137, name: 'Malta',	code:  'MT', icon: 'mt.png'},
    { id: 138, name: 'Marshall Islands', code:  'MH', icon: 'mh.png'},
    { id: 139, name: 'Martinique',	code:  'MQ', icon: 'mq.png'},
    { id: 140, name: 'Mauritania',	code:  'MR', icon: 'mr.png'},
    { id: 141, name: 'Mauritius',	code:  'MU', icon: 'mu.png'},
    { id: 142, name: 'Mayotte',	code:  'YT', icon: 'yt.png'},
    { id: 143, name: 'Mexico',	code:  'MX', icon: 'mx.png'},
    { id: 144, name: 'Micronesia', code:  'FM', icon:  'fm.png'},
    { id: 145, name: 'Moldova', code:  'MD', icon: 'md.png'},
    { id: 146, name: 'Monaco',	code:  'MC', icon: 'mc.png'},
    { id: 147, name: 'Mongolia',	code:  'MN', icon: 'mn.png'},
    { id: 148, name: 'Montenegro',	code:  'ME', icon: 'me.png'},
    { id: 149, name: 'Montserrat',	code:  'MS', icon: 'ms.png'},
    { id: 150, name: 'Morocco',	code:  'MA', icon: 'ma.png'},
    { id: 151, name: 'Mozambique',	code:  'MZ', icon: 'mz.png'},
    { id: 152, name: 'Myanmar',	code:  'MM', icon: 'mm.png'},
    { id: 153, name: 'Namibia',	code:  'NA', icon: 'na.png'},
    { id: 154, name: 'Nauru',	code:  'NR', icon: 'nr.png'},
    { id: 155, name: 'Nepal',	code:  'NP', icon: 'np.png'},
    { id: 156, name: 'Netherlands', code:  'NL', icon: 'nl.png'},
    { id: 157, name: 'New Caledonia', code:  'NC', icon: 'nc.png'},
    { id: 158, name: 'New Zealand', code:  'NZ', icon: 'nz.png'},
    { id: 159, name: 'Nicaragua',	code:  'NI', icon: 'ni.png'},
    { id: 160, name: 'Niger', code:  'NE', icon: 'ne.png'},
    { id: 161, name: 'Nigeria',	code:  'NG', icon: 'ng.png'},
    { id: 162, name: 'Niue',	code:  'NU', icon: 'nu.png'},
    { id: 163, name: 'Norfolk Island', code:  'NF', icon: 'NF.png'},
    { id: 164, name: 'Northern Mariana Islands', code:  'MP', icon: 'mp.png'},
    { id: 165, name: 'Norway',	code:  'NO', icon: 'no.png'},
    { id: 166, name: 'Oman',	code:  'OM', icon: 'om.png'},
    { id: 167, name: 'Pakistan',	code:  'PK', icon: 'pk.png'},
    { id: 168, name: 'Palau',	code:  'PW', icon: 'pw.png'},
    { id: 169, name: 'Palestine', code:  'PS', icon: 'ps.png'},
    { id: 170, name: 'Panama',	code:  'PA', icon: 'pa.png'},
    { id: 171, name: 'Papua New Guinea', code:  'PG', icon: 'pg.png'},
    { id: 172, name: 'Paraguay',	code:  'PY', icon: 'py.png'},
    { id: 173, name: 'Peru',	code:  'PE', icon: 'pe.png'},
    { id: 174, name: 'Philippines', code:  'PH', icon: 'ph.png'},
    { id: 175, name: 'Pitcairn',	code:  'PN', icon: 'pn.png'},
    { id: 176, name: 'Poland',	code:  'PL', icon: 'pl.png'},
    { id: 177, name: 'Portugal',	code:  'PT', icon: 'pt.png'},
    { id: 178, name: 'Puerto Rico', code:  'PR', icon: 'pr.png'},
    { id: 179, name: 'Qatar',	code:  'QA', icon: 'qa.png'},
    { id: 180, name: 'Republic of North Macedonia', code:  'MK', icon: 'mk.png'},
    { id: 181, name: 'Romania', code:  'RO', icon: 'ro.png'},
    { id: 182, name: 'Russian  Federation', code:  'RU', icon: 'ru.png'},
    { id: 183, name: 'Rwanda',	code:  'RW', icon: 'rw.png'},
    { id: 184, name: 'Réunion', code:  'RE', icon: 're.png'},
    { id: 185, name: 'Saint Barthélemy', code:  'BL', icon: 'bl.png'},
    { id: 186, name: 'Saint Helena, Ascension and Tristan', code:  'SH', icon: 'sh.png'},
    { id: 187, name: 'Saint Kitts and Nevis', code:  'KN', icon: 'kn.png'},
    { id: 188, name: 'Saint Lucia', code:  'LC', icon: 'lc.png'},
    { id: 189, name: 'Saint Martin', code:  'MF', icon: 'mf.png'},
    { id: 190, name: 'Saint Pierre and Miquelon', code:  'PM', icon: 'pm.png'},
    { id: 191, name: 'Saint Vincent and Grenadines', code:  'VC', icon: 'vc.png'},
    { id: 192, name: 'Samoa',	code:  'WS', icon: 'ws.png'},
    { id: 193, name: 'San Marino', code:  'SM', icon: 'sm.png'},
    { id: 194, name: 'Sao Tome and Principe', code:  'ST', icon: 'st.png'},
    { id: 195, name: 'Saudi Arabia', code:  'SA', icon: 'sa.png'},
    { id: 196, name: 'Senegal',	code:  'SN', icon: 'sn.png'},
    { id: 197, name: 'Serbia',	code:  'RS', icon: 'rs.png'},
    { id: 198, name: 'Seychelles',	code:  'SC', icon: 'sc.png'},
    { id: 199, name: 'Sierra Leone', code:  'SL', icon: 'sl.png'},
    { id: 200, name: 'Singapore',	code:  'SG', icon: 'sg.png'},
    { id: 201, name: 'Sint Maarten', code:  'SX', icon: 'sx.png'},
    { id: 202, name: 'Slovakia',	code:  'SK', icon: 'sk.png'},
    { id: 203, name: 'Slovenia',	code:  'SI', icon: 'si.png'},
    { id: 204, name: 'Solomon Islands', code:  'SB', icon: 'sb.png'},
    { id: 205, name: 'Somalia',	code:  'SO', icon: 'so.png'},
    { id: 206, name: 'South  Africa', code:  'ZA', icon: 'za.png'},
    { id: 207, name: 'South Georgia', code:  'GS', icon: 'gs.png'},
    { id: 208, name: 'South Sudan', code:  'SS', icon: 'ss.png'},
    { id: 209, name: 'Spain',	code:  'ES', icon: 'es.png'},
    { id: 210, name: 'Sri Lanka', code:  'LK', icon: 'lk.png'},
    { id: 211, name: 'Sudan', code:  'SD', icon: 'sd.png'},
    { id: 212, name: 'Suriname',	code:  'SR', icon: 'sr.png'},
    { id: 213, name: 'Svalbard and Jan Mayen', code:  'SJ', icon: 'sj.png'},
    { id: 214, name: 'Sweden',	code:  'SE', icon: 'se.png'},
    { id: 215, name: 'Switzerland',	code:  'CH', icon: 'ch.png'},
    { id: 216, name: 'Syrian Arab Republic', code:  'SY', icon: 'sy.png'},
    { id: 217, name: 'Taiwan', code:  'TW', icon: 'tw.png'},
    { id: 218, name: 'Tajikistan',	code:  'TJ', icon: 'tj.png'},
    { id: 219, name: 'Tanzania', code:  'TZ', icon: 'tz.png'},
    { id: 220, name: 'Thailand',	code:  'TH', icon: 'th.png'},
    { id: 221, name: 'Timor-Leste', code:  'TL', icon: 'tl.png'},
    { id: 222, name: 'Togo',	code:  'TG', icon: 'tg.png'},
    { id: 223, name: 'Tokelau',	code:  'TK', icon: 'tk.png'},
    { id: 224, name: 'Tonga',	code:  'TO', icon: 'to.png'},
    { id: 225, name: 'Trinidad and Tobago', code:  'TT', icon: 'tt.png'},
    { id: 226, name: 'Tunisia',	code:  'TN', icon: 'tn.png'},
    { id: 227, name: 'Turkey',	code:  'TR', icon: 'tr.png'},
    { id: 228, name: 'Turkmenistan',	code:  'TM', icon: 'tm.png'},
    { id: 229, name: 'Turks and Caicos Islands', code:  'TC', icon: 'tc.png'},
    { id: 230, name: 'Tuvalu',	code:  'TV', icon: 'tv.png'},
    { id: 231, name: 'Uganda',	code:  'UG', icon: 'ug.png'},
    { id: 232, name: 'Ukraine',	code:  'UA', icon: 'ua.png'},
    { id: 233, name: 'United Arab Emirates', code:  'AE', icon: 'ae.png'},
    { id: 234, name: 'United Kingdom', code:  'GB', icon: 'gb.png'},
    { id: 235, name: 'United States Minor', code:  'UM', icon: 'um.png'},
    { id: 236, name: 'United State of America', code:  'US', icon: 'us.png'},
    { id: 237, name: 'Uruguay',	code:  'UY', icon: 'uy.png'},
    { id: 238, name: 'Uzbekistan',	code:  'UZ', icon: 'uz.png'},
    { id: 239, name: 'Vanuatu',	code:  'VU', icon: 'vu.png'},
    { id: 240, name: 'Venezuela', code:  'VE', icon: 've.png'},
    { id: 241, name: 'Viet Nam', code:  'vn', icon: 'vn.png'},
    { id: 242, name: 'Virgin Islands (British)', code:  'VG', icon: 'vg.png'},
    { id: 243, name: 'Virgin Islands (U.S)', code:  'VI', icon: 'vi.png'},
    { id: 244, name: 'Wallis and Futuna', code:  'WF', icon: 'wf.png'},
    { id: 245, name: 'Western Sahara', code:  'EH', icon: 'eh.png'},
    { id: 246, name: 'Yemen',	code:  'YE', icon: 'ye.png'},
    { id: 247, name: 'Zambia',	code:  'ZM', icon: 'zm.png'},
    { id: 248, name: 'Zimbabwe',	code:  'ZW', icon: 'zw.png'},
    { id: 249, name: 'Åland Islands', code:  'AX', icon: 'ax.png'},
]
